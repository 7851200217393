exports.ActionType = {
    CREATE_DASHBOARD: "createDashboard",
    SELECT_DASHBOARD: "selectDashboard",
    DESELECT_DASHBOARD: "deselectDashboard",
    DELETE_DASHBOARD: "deleteDashboard",
    UPDATE_DASHBOARD_TITLE: "updateDashboardTitle",
    CREATE_PAGE: "createPage",
    SELECT_PAGE: "selectPage",
    DELETE_PAGE: "deletePage",
    OPEN_ELEMENT_MODAL_CREATE: "openModalCreate",
    OPEN_ELEMENT_MODAL_EDIT: "openModalEdit",
    CREATE_ELEMENT: "createElement",
    OPEN_STEP_TYPE: "openStepType",
    CLOSE_STEP_TYPE: "closeStepType",
    TOGGLE_STEP_TYPE: "toggleStepType",    
    OPEN_STEP_SIZE: "openStepSize",
    CLOSE_STEP_SIZE: "closeStepSize",
    TOGGLE_STEP_SIZE: "toggleStepSize",    
    OPEN_STEP_SOURCE: "openStepSource",
    CLOSE_STEP_SOURCE: "closeStepSource",
    TOGGLE_STEP_SOURCE: "toggleStepSource",    
    OPEN_STEP_CUSTOM: "openStepCustom",
    CLOSE_STEP_CUSTOM: "closeStepCustom",
    TOGGLE_STEP_CUSTOM: "toggleStepCustom",    
    TYPE_STEP_COMPLETE: "typeStepComplete",
    SIZE_STEP_COMPLETE: "sizeStepComplete",
    SOURCE_STEP_COMPLETE: "sourceStepComplete",
    CUSTOM_STEP_COMPLETE: "customStepComplete",
    GRAPH_DATA_UPDATED: "graphDataUpdated",
    SECONDARY_Y_UPDATED: "secondaryYUpdated",
    TEXT_UPDATED: "textUpdated",
    ELEMENT_MENU_EDIT: "elementMenuEdit",
    ELEMENT_MENU_DELETE: "elementMenuDelete",
    SETTER_GRID_UPDATE: "setterUiUpdate",
    SETTER_ROW_UPDATE: "setterRowUpdate",
    SETTER_COLUMN_UPDATE: "setterColumnUpdate",
    SETTER_WIDTH_UPDATE: "setterWidthUpdate",
    SETTER_HEIGHT_UPDATE: "setterHeightUpdate",
    EXPORT_MODAL_OPEN: "exportModalOpen",
    SET_CANVAS_MODE: "setCanvasMode",
    ELEMENT_LOAD_COMPLETE: "elementLoadComplete",
    CANVAS_LOAD_COMPLETE: "canvasLoadComplete",
    SET_ELEMENT_SUBTYPE: "setElementSubtype",
    COLUMN_WIDTHS_UPDATED: "columnWidthsUpdated"
};

exports.CANVAS_ROWS = 3;
exports.CANVAS_COLS = 4;

exports.GRID_PAGE_SIZE = 6;

exports.ElementType = {
    GRAPH: "graph",
    TABLE: "table",
    TEXT: "text",
    SNAPSHOT: "snapshot",
    CREATE: "create",
    NONE: ""
};

exports.ElementSize = {
    SMALL: {
        value: "small",
        label: "Small",
        width: 1,
        height: 1
    },
    MEDIUM: {
        value: "medium",
        label: "Medium",
        width: 2,
        height: 2
    },
    LARGE: {
        value: "large",
        label: "Large",
        width: 3,
        height: 3
    },
    EXTRA_LARGE: {
        value: "xlarge",
        label: "Extra Large",
        width: 4,
        height: 3
    },
    NONE: {
        value: "",
        label: "",
        width: 0,
        height: 0
    }
};

exports.ElementSubType = {
    XY_GRAPH: "xygraph",
    PIE_CHART: "pie",
    SIMPLE_INDICATOR_TABLE: "simpletable",
    GROUPED_INDICATOR_TABLE: "groupedtable",
    NONE: ""
};

exports.DatasetGraphType = {
    BAR: "bar",
    LINE: "line",
    PIE: "pie",
    NONE: ""
};

exports.Step = {
    TYPE: {
        index: 0,
        value: "stepType"
    },
    SIZE: {
        index: 1,
        value: "stepSize"
    },
    SOURCE: {
        index: 2,
        value: "stepSource"
    },
    CUSTOM: {
        index: 3,
        value: "stepCustom"
    }
}

exports.StepStatus = {
    ACTIVE: "active",
    INCOMPLETE: "incomplete",
    COMPLETE: "completess"
};

exports.GraphColor = {
    BLUE: {
        value: "#1f77b4",
        label: "Blue"
    },
    ORANGE: {
        value: "#ff7f0e",
        label: "Orange"
    },
    GREEN: {
        value: "#2ca02c",
        label: "Green"
    },
    RED: {
        value: "#d62728",
        label: "Red"
    },
    PURPLE: {
        value: "#9467bd",
        label: "Purple"
    },
    BROWN: {
        value: "#8c564b",
        label: "Brown"
    },
    PINK: {
        value: "#e377c2",
        label: "Pink"
    },
    GRAY: {
        value: "#7f7f7f",
        label: "Gray"
    },
    YELLOW_GREEN: {
        value: "#bcbd22",
        label: "Yellow green"
    },
    TEAL: {
        value: "#17becf",
        label: "Teal"
    }
};

exports.ExportType = {
    ALL_PAGES: "All",
    CURRENT_PAGE: "Current"
}

exports.PageOrientation = {
    PORTRAIT: "Portrait",
    LANDSCAPE: "Landscape"
}

exports.CanvasMode = {
    VIEW: "view",
    EDIT: "edit",
    EXPORT: "export",
    EXPORT_ALL: "exportAll"
}

exports.ThumbType = {
    NONE: {
        key: "none",
        color: "#000000",
        background: "bg-grey03",
        backgroundColor: "#F0F1F3"
    },
    QUESTION: {
        key: "question",
        color: "#4B5163",
        background: "bg-grey03",
        backgroundColor: "#F0F1F3"
    },
    TEXT: {
        key: "text",
        color: "#9C17CB",
        background: "bg-purple03",
        backgroundColor: "#EECBFA"
    },
    LINE: {
        key: "line",
        color: "#1A53E3",
        background: "bg-blue03",
        backgroundColor: "#D8E3FF"
    },
    BAR: {
        key: "bar",
        color: "#1A53E3",
        background: "bg-blue03",
        backgroundColor: "#D8E3FF"
    },
    PIE: {
        key: "pie",
        color: "#1A53E3",
        background: "bg-blue03",
        backgroundColor: "#D8E3FF"
    },
    TABLE: {
        key: "table",
        color: "#16BA76",
        background: "bg-green03",
        backgroundColor: "#BAF7DB"
    },
}

exports.FORE_LIGHTNESS = 30;
exports.BACK_LIGHTNESS = 90;

exports.IndentReplacements = [
    {
        REMOVE: "ql-indent-1",
        ADD: "pl-18",
        TYPE: "a"
    },
    {
        REMOVE: "ql-indent-2",
        ADD: "pl-30",
        TYPE: "i"
    },
    {
        REMOVE: "ql-indent-3",
        ADD: "pl-42",
        TYPE: "1"
    },
    {
        REMOVE: "ql-indent-4",
        ADD: "pl-54",
        TYPE: "a"
    },
    {
        REMOVE: "ql-indent-5",
        ADD: "pl-66",
        TYPE: "i"
    },
    {
        REMOVE: "ql-indent-6",
        ADD: "pl-74",
        TYPE: "1"
    },
    {
        REMOVE: "ql-indent-7",
        ADD: "pl-90",
        TYPE: "a"
    },
    {
        REMOVE: "ql-indent-8",
        ADD: "pl-98",
        TYPE: "i"
    }
];

exports.SetterGridStatus = {
    UNKNOWN: 0,
    EXCLUDED: 1,
    VACANT: 2,
    OCCUPIED: 3
}

exports.OverlapStatus = {
    NONE: 0,
    OVERLAP: 1,
    ERROR: 2
}

exports.CURRENT_EDITOR_VERSION = 2;

exports.PDF_MARGIN = 10;